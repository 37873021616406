import { Form, Input, Select } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";

const FeaturedFactCheck = (props) => {
  const { set_fact_check_id, set_type, is_editing } = props;
  const { items: featured_fact_checks, loading: featured_fact_checks_loading } =
    useDynamicSelector("featured_fact_check");
  const on_change = (value) => {
    set_fact_check_id(value);
    set_type("fact_check");
  };

  return (
    <Form.Item
      name="fact_check_id"
      label="Fact check"
      rules={[
        {
          required: true,
          message: "Please choose the fact check",
        },
      ]}
    >
      {is_editing ? (
        <Select
          onChange={on_change}
          loading={featured_fact_checks_loading || false}
        >
          {featured_fact_checks?.map((option, index) => (
            <Select.Option key={option?.id} value={option?.id}>
              {option?.title}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Input readOnly disabled={true} />
      )}
    </Form.Item>
  );
};

export default FeaturedFactCheck;
