import { Button, Modal, Pagination, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_clear,
  dynamic_request,
  get_all_approval_articles_query,
  get_all_editors_article_query,
  get_all_my_articles_query,
  get_all_published_article_query,
  mutation_delete_article,
  query_get_all_article,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";
import {
  get_status,
  navigate,
  navigateWithProps,
  retrieveItem,
} from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import { countFormat } from "../../../../helpers/constants";
import { HStack, Pressable } from "native-base";
import { toast } from "react-toastify";
import moment from "moment";
import { FaEye } from "react-icons/fa";

const AdminArticleList = (props) => {
  const {
    set_article_modal_open,
    set_modal_title,
    set_form_values,
    type,
    language_id,
    article_type,
    set_article_type,
    search_string,
    from_date,
    to_date,
    default_language,
    new_default_language_state,
    back_button_true_state,
    set_back_button_click,
    status_option,
    page_number,
    page_limit,
    set_page_limit,
    set_page_number,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const [delete_id, set_delete_id] = useState("");
  const role = retrieveItem("role");

  const {
    items: article_list,
    loading: article_list_loading,
    pagination: article_list_pagination,
  } = useDynamicSelector("get_all_my_article");

  const {
    items: user_article_list,
    loading: user_article_list_loading,
    pagination: user_article_list_pagination,
  } = useDynamicSelector("get_all_previleged_user_article");

  const {
    items: editors_article_list,
    loading: editors_article_loading,
    pagination: editors_article_pagination,
  } = useDynamicSelector("get_all_editor_article");

  const {
    items: all_article_list,
    loading: all_article_loading,
    pagination: all_article_pagination,
  } = useDynamicSelector("get_all_published_article");

  const {
    status: delete_article_status,
    loading: delete_article_loading,
    error: delete_article_error,
  } = useDynamicSelector("delete_article");

  const { status: create_article_status } =
    useDynamicSelector("create_article");

  useEffect(() => {
    if (create_article_status === "Success") {
      set_article_type("my_article");
      dispatch(dynamic_clear("create_article"));
    }
  }, [create_article_status]);

  useEffect(() => {
    dispatch(dynamic_clear("process_article"));
    dispatch(dynamic_clear("update_article"));
    dispatch(dynamic_clear("create_article"));
    if (article_type === "user_article") {
      get_all_user_article_list(to_date ? 1 : page_number, page_limit);
    } else if (article_type === "my_article") {
      get_my_article_list(to_date ? 1 : page_number, page_limit);
    } else if (article_type === "editor_article") {
      get_editors_article(to_date ? 1 : page_number, page_limit);
    } else if (article_type === "all_article") {
      get_all_articles(to_date ? 1 : page_number, page_limit);
    }
  }, [
    article_type,
    language_id,
    page_limit,
    page_number,
    search_string,
    from_date,
    to_date,
    default_language,
    status_option,
  ]);

  useEffect(() => {
    if (delete_article_status === "Success") {
      toast.success("Article deleted successfully");
      handle_delete_modal_close();
      get_my_article_list(page_number, page_limit);
      dispatch(dynamic_clear("delete_article"));
    } else if (delete_article_error) {
      toast.error(delete_article_error?.message);
      dispatch(dynamic_clear("delete_article"));
    }
  }, [delete_article_status, delete_article_error]);
  
 
  const handle_edit_article = (record) => {
    dispatch(dynamic_clear("get_article"));
    set_article_modal_open(true);
    set_modal_title("edit");
    navigateWithProps(`${ROUTES.ADMIN_ARTICLE_DETAILS}/${record?.id}`, {
      modal_title: "edit",
      record: record,
      article_type: article_type,
      default_language,
      set_back_button_click,
    });
    // navigate(`${ROUTES.ADMIN_ARTICLE_DETAILS}/${record?.id}`);
    // set_form_values(record);
  };

  const get_article_view = (record) => {
    console.log("record", record);

    let base_url =
      record?.language_id === "839bcc29-853f-4c4c-8700-98fd88558952"
        ? process.env.REACT_APP_WEB_URL
        : process.env.REACT_APP_EN_WEB_URL;

    const url = `${base_url}articles/${record?.perma_link}.html`;
    window.open(url, "_blank");
  };

  const get_article_history = (record) => {
    navigateWithProps(`${ROUTES.ARTICLE_HISTORY}/${record?.id}`, {
      record: record,
      article_type: article_type,
      default_language,
    });
  };

  const get_my_article_list = (page_number, page_limit) => {
    let key = [{ key: "get_all_my_article", loading: true }];
    let query = get_all_my_articles_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      // language_id: default_language === "All" ? "" : language_id,
      language_id:
        default_language === "All"
          ? ""
          : back_button_true_state
          ? new_default_language_state === ""
            ? language_id
            : new_default_language_state
          : language_id,
      title: search_string,
      from_date: from_date,
      to_date: to_date,
      status: status_option,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_user_article_list = (page_number, page_limit) => {
    let key = [{ key: "get_all_previleged_user_article", loading: true }];
    let query = get_all_approval_articles_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      json: {
        // language_id: default_language === "All" ? "" : language_id,
        language_id:
          default_language === "All"
            ? ""
            : back_button_true_state
            ? new_default_language_state === ""
              ? language_id
              : new_default_language_state
            : language_id,
        search_string: search_string,
        from_date: from_date,
        to_date: to_date,
        status: status_option,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_editors_article = (page_number, page_limit) => {
    let key = [{ key: "get_all_editor_article", loading: true }];
    let query = get_all_editors_article_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      json: {
        // language_id: default_language === "All" ? "" : language_id,
        language_id:
          default_language === "All"
            ? ""
            : back_button_true_state
            ? new_default_language_state === ""
              ? language_id
              : new_default_language_state
            : language_id,
        search_string: search_string,
        from_date: from_date,
        to_date: to_date,
        status: status_option,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const get_all_articles = (page_number, page_limit) => {
    let key = [{ key: "get_all_published_article", loading: true }];
    let query = get_all_published_article_query;
    let variables = {
      page_number: page_number,
      page_limit: page_limit,
      json: {
        // language_id: default_language === "All" ? "" : language_id,
        language_id:
          default_language === "All"
            ? ""
            : back_button_true_state
            ? new_default_language_state === ""
              ? language_id
              : new_default_language_state
            : language_id,
        search_string: search_string,
        from_date: from_date,
        to_date: to_date,
        status: status_option,
      },
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const delete_article = () => {
    let key = [{ key: "delete_article", loading: true }];
    let query = mutation_delete_article;
    let variables = {
      id: delete_id,
    };
    dispatch(dynamic_request(key, query, variables));
  };

  const handle_delete_article = (record) => {
    set_delete_modal_open(true);
    set_delete_id(record?.id);
  };

  const handle_delete_modal_close = () => {
    set_delete_modal_open(false);
  };

  const action_items = (record) => {
    return (
      <Space
        style={{
          gap: "20px",
        }}
      >
        {!(role === "Editor" && record?.status === "published") && (
          <Pressable onPress={() => handle_edit_article(record)}>
            <Tooltip title="Edit" placement="top">
              <EditOutlined />
            </Tooltip>
          </Pressable>
        )}
        {!(role === "Editor" && record?.status === "published") && (
          <Tooltip title="Delete" placement="top">
            <Pressable onPress={() => handle_delete_article(record)}>
              <DeleteOutlined />
            </Pressable>
          </Tooltip>
        )}

        {record?.log_count > 0 && (
          <Tooltip title="History" placement="top">
            <Pressable onPress={() => get_article_history(record)}>
              <HistoryOutlined />
            </Pressable>
          </Tooltip>
        )}
        {record?.status === "published" &&
          new Date(record.published_date_time) <= new Date() && (
            <Tooltip title="View" placement="top">
              <Pressable onPress={() => get_article_view(record)}>
                <FaEye />
              </Pressable>
            </Tooltip>
          )}
      </Space>
    );
  };

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Author",
      key: "post_by",
      render: (record) => {
        return record?.staff_profile?.name || record?.user_profile?.name || "-";
      },
    },
    {
      title: "Created date",
      key: "create_date",
      render: (record) => {
        return moment(record?.created_date_time).format("DD/MM/YYYY");
      },
    },
    ...(role === "Admin"
      ? [
          {
            title: "Published date",
            key: "published_date",
            render: (record) => {
              const current_date_time = moment();
              const published_date_time = moment(record?.published_date_time);
              if (
                published_date_time.isValid() &&
                published_date_time.isSameOrBefore(current_date_time)
              ) {
                return published_date_time.format("DD/MM/YYYY HH:mm:ss");
              }
              return "-";
            },
          },
        ]
      : []),
    {
      title: t("status"),
      key: "status",
      render: (record) => {
        const current_date_time = moment();
        const published_date_time = moment(record?.published_date_time);
        if (
          published_date_time.isValid() &&
          published_date_time.isAfter(current_date_time)
        ) {
          return "Scheduled";
        }
        return get_status(record?.status);
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>{t("actions")}</div>,
      dataIndex: "",
      key: "actions",
      render: (record) => action_items(record),
    },
  ];
  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };
  let dataSource;
  if (article_type) {
    dataSource =
      article_type === "my_article"
        ? article_list
        : article_type === "user_article"
        ? user_article_list
        : article_type === "editor_article"
        ? editors_article_list
        : article_type === "all_article"
        ? all_article_list
        : "";
  } else {
    dataSource = article_list;
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={
          article_type === "my_article"
            ? article_list_loading
            : article_type === "user_article"
            ? user_article_list_loading
            : article_type === "editor_article"
            ? editors_article_loading
            : article_type === "all_article"
            ? all_article_loading
            : ""
        }
        pagination={false}
      />
      <HStack justifyContent={"flex-end"} mt={"20px"}>
        <Pagination
          total={
            article_type === "my_article"
              ? article_list_pagination?.total_count
              : article_type === "user_article"
              ? user_article_list_pagination?.total_count
              : article_type === "editor_article"
              ? editors_article_pagination?.total_count
              : article_type === "all_article"
              ? all_article_pagination?.total_count
              : ""
          }
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetch_data}
          showTotal={(total, range) => `Total ${countFormat(total)} items`}
        />
      </HStack>
      <Modal
        title={"Delete Article"}
        open={delete_modal_open}
        onCancel={handle_delete_modal_close}
        footer={[
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              key="cancel"
              onClick={() => handle_delete_modal_close()}
              danger
            >
              Cancel
            </Button>
            <Button
              key="confirm"
              type="primary"
              onClick={() => delete_article()}
              loading={delete_article_loading}
            >
              Ok
            </Button>
          </div>,
        ]}
      >
        Are you sure to delete ?
      </Modal>
    </div>
  );
};

export default AdminArticleList;
