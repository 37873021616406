import { gql } from "@apollo/client";

export const create_featured_stories_query=gql`
mutation create_featured_story($json: featured_story_input) {
    create_featured_story(json: $json) {
      id
      status
      error {
        message
      }
    }
  }`;

  export const get_all_featured_stories_query=gql`
  query get_all_featured_story($language_id:String!){
    get_all_featured_story(language_id:$language_id){
      items{
        type
        language_id
        fact_check_id
        article_id
        youtube_video_id
        article{
        id
        title
        }
        fact_check{
        id
        title
        }
        youtube_video{
        id
        title
        }
      }
    }
  }`;

  export const get_all_you_turn_videos_query=gql`
  query get_videos_from_channel($next_page_token:String){
   get_you_turn_videos:get_videos_from_channel(next_page_token:$next_page_token){
      items{
        id
        title
        video_id
        updated_at
      }
    }
  }`

  export const get_featured_article_query=gql`
  query get_all_article($json:get_all_article_input){
    featured_article:get_all_article(json:$json){
      items{
        id
        language_id
        title
      }
    }
  }`;

  export const get_all_featured_fact_check_query=gql`
query get_all_fact_check(
  $json: get_all_fact_check_input
  $page_limit: Int
  $page_number: Int
) {
  featured_fact_check: get_all_fact_check(
    json: $json
    page_number: $page_number
    page_limit: $page_limit
  ) {
    items {
      id
      language_id
      title
    }
  }
}
  `;

  export const get_all_featured_user_articles_query=gql`
  query get_all_previleged_user_article($json: get_all_article_input) {
    user_contributed_articles:get_all_previleged_user_article(json: $json) {
      items {
        id
        language_id
        title
        subtitle
      }
    }
  }
  `;

  export const get_all_featured_user_fact_check_query=gql`
  query get_all_previleged_user_fact_check($json: get_all_fact_check_input) {
  user_contributed_fact_check: get_all_previleged_user_fact_check(json: $json) {
    items {
      id
      language_id
      title
      subtitle
    }
  }
}
`

  export const sync_videos=gql`
  mutation sync_videos_from_channel {
    sync_videos_from_channel {
      id
      status
      error {
        message
      }
    }
  }
  `;